import Modal from 'react-bootstrap/Modal';
import ModalPortal from './ModalPortal';
import '../components/css/findfp_main.scss';
import '../components/css/findfp_common.scss';
import '../components/css/business_card.scss';
import { useState } from 'react';

const FilterModal = ({ onClose, isOpen, professionalChecked, responseAreaChecked, setProfessionalChecked, setResponseAreaChecked}: any) => {
    const [filterProfessionalChecked, setFilterProfessionalChecked] = useState<string>(professionalChecked);
    const [filterResponseAreaChecked, setFilterResponseAreaChecked] = useState<string>(responseAreaChecked);

    const onProfessionalChange = (e:any) => {
        setFilterProfessionalChecked(e.target.value);
    }

    const onResponseAreaChange = (e:any) => {
        setFilterResponseAreaChecked(e.target.value);
    }

    const externalDataSet = (professional:string, responseArea:string) => {
        setProfessionalChecked(professional);
        setResponseAreaChecked(responseArea);
    }

    return (
        <ModalPortal>
            <Modal show={isOpen}>
                <div className="modal-header bg-light">
                    <div className="text-center"><i className="bi bi-filter-circle-fill me-1"></i>필터 설정</div>
                </div>
                <div className="modal-body pb-0">
                    <div className="mb-3">· 전문분야</div>
                    <div className="row mx-0">
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="professional_total"
                                    value="전체" checked={filterProfessionalChecked === "전체"} onChange={onProfessionalChange} />
                                <label className="form-check-label" htmlFor="professional_total">전체</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="cancer" value="암" 
                                checked={filterProfessionalChecked === "암"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="cancer">암</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="dementia" value="치매/간병" 
                                checked={filterProfessionalChecked === "치매/간병"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="dementia">치매/간병</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="baby" value="태아/어린이" 
                                checked={filterProfessionalChecked === "태아/어린이"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="baby">태아/어린이</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="kid" value="치아보험" 
                                checked={filterProfessionalChecked === "치아보험"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="kid">치아보험</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="car" value="자동차/운전자" 
                                checked={filterProfessionalChecked === "자동차/운전자"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="car">자동차/운전자</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="animal" value="펫보험" 
                                checked={filterProfessionalChecked === "펫보험"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="animal">펫보험</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="professional_etc"
                                    value="종신보험" checked={filterProfessionalChecked === "종신보험"} onChange={onProfessionalChange} />
                                <label className="form-check-label" htmlFor="professional_etc">종신보험</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input" type="radio" name="professional_options" id="sickness" value="유병자건강보험" 
                                checked={filterProfessionalChecked === "유병자건강보험"} onChange={onProfessionalChange}/>
                                <label className="form-check-label" htmlFor="sickness">유병자건강보험</label>
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="mb-3">· 지역</div>
                    <div className="row mx-0 mb-3">
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="response_area_total"
                                    value="전체" checked={filterResponseAreaChecked === "전체"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="response_area_total">전체</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="kangwondo" value="춘천/강원"
                                checked={filterResponseAreaChecked === "춘천/강원"} onChange={onResponseAreaChange} />
                                <label className="form-check-label" htmlFor="kangwondo">춘천/강원</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="seoul" value="서울" 
                                checked={filterResponseAreaChecked === "서울"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="seoul">서울</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="kyongki" value="경기" 
                                checked={filterResponseAreaChecked === "경기"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="kyongki">경기</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="incheon" value="인천/부천" 
                                checked={filterResponseAreaChecked === "인천/부천"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="incheon">인천/부천</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="daegu" value="대구/경북" 
                                checked={filterResponseAreaChecked === "대구/경북"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="daegu">대구/경북</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="jeju" value="제주" 
                                checked={filterResponseAreaChecked === "제주"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="jeju">제주</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="cheongju" value="청주/충북" 
                                checked={filterResponseAreaChecked === "청주/충북"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="cheongju">청주/충북</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="daegeon" value="대전/충남/세종" 
                                checked={filterResponseAreaChecked === "대전/충남/세종"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="daegeon">대전/충남/세종</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="jeonju" value="전주/전북"
                                checked={filterResponseAreaChecked === "전주/전북"} onChange={onResponseAreaChange} />
                                <label className="form-check-label" htmlFor="jeonju">전주/전북</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="busan" value="부산/울산/경남" 
                                checked={filterResponseAreaChecked === "부산/울산/경남"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="busan">부산/울산/경남</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-check form-check-block mb-2">
                                <input className="form-check-input" type="radio" name="response_area_options" id="gwangju" value="광주/전남" 
                                checked={filterResponseAreaChecked === "광주/전남"} onChange={onResponseAreaChange}/>
                                <label className="form-check-label" htmlFor="gwangju">광주/전남</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="d-flex w-100">
                        <div className="w-50 pe-1">
                            <a className="w-100 btn btn-secondary py-2" onClick={onClose}>
                                닫기
                            </a>
                        </div>
                        <div className="w-50 ps-1">
                            <a id="filter_search" className="w-100 btn btn-bocare py-2" onClick={() => {setProfessionalChecked(filterProfessionalChecked);setResponseAreaChecked(filterResponseAreaChecked); onClose();}}>
                                검색
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default FilterModal;