import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import LicenseModal from "./LicenseModal";
import ModalPortal from './ModalPortal';
import '../components/css/findfp_main.scss';
import '../components/css/findfp_common.scss';
import '../components/css/business_card.scss';
import {BlobServiceClient} from "@azure/storage-blob";

const ProfileModal = ({ onClose, plannerInfo, isOpen }: any) => {
    const restUrl = process.env.REACT_APP_FINDFP_REST_URL!;
    const [license, setLicense] = useState([]);
    const [introduce, setIntroduce]: any = useState({});
    const [licenseModalOpen, setLicenseModalOpen] = useState<boolean>();
    let [licenseInfo, setLicenseInfo]: any = useState({});
    const [imageSrc, setImageSrc] = useState('');
    const blobServiceClient = new BlobServiceClient(`https://${process.env.REACT_APP_STORAGE_ACCOUNT}.blob.core.windows.net/?${process.env.REACT_APP_SAS_TOKEN}`);
    const containerClient = blobServiceClient.getContainerClient("bocare");

    const HandleLicenseModalShow = () => {
        setLicenseModalOpen(false);
    };

    const licenseOpen = (list: Object) => {
        setLicenseInfo(list);
        setLicenseModalOpen(true);
    }

    const openInsuranceRequest = () => {
        window.open(`${process.env.REACT_APP_BOCARE_URL}v2/views/planner/insuranceRequest/agree/?phone=${plannerInfo.mem_phone}&loc=findfp`,'보험금청구' ,'width=600, height=1700');
    }

    const openPlannerBoard = () => {
        window.open(`${process.env.REACT_APP_BOCARE_URL}v2/views/planner/board/list/index.html?phone=${plannerInfo.mem_phone}&loc=findfp`,'게시판' ,'width=600, height=1700');
    }

    let openInquiry = (planner_phone: any) => {
        window.open(`${process.env.REACT_APP_BOCARE_URL}v2/views/planner/insurance_inquiry/inquiry/index.html?phone=${planner_phone}&loc=findfp`, '1:1문의', 'width=600, height=1700');
    }

    useEffect(() => {
        const licenseData = async () => {
            const result = await axios.get(restUrl, { params: { type: "license", plannerId: plannerInfo.idx } });
            setLicense(result.data);
        }

        const introduceData = async () => {
            const result = await axios.get(restUrl, { params: { type: "introduce", plannerId: plannerInfo.idx } });
            setIntroduce(result.data);
            if(result.data == null){
                setIntroduce({
                    "info_txt" : ""
                });
            }
        }

        const fetchImage = async () => {
            try {
                const cleanEmail = plannerInfo.mem_email.replace(/\./g, '').replace(/@/g, '');
                const blobClient = containerClient.getBlobClient(`planner/${cleanEmail}/profile`)
                const response = await blobClient.download();
                const blob = await response.blobBody;
                // @ts-ignore
                const imageUrl = URL.createObjectURL(blob);
                setImageSrc(imageUrl);

            } catch (error) {
                setImageSrc("./images/no_profile_photo.png")
            }
        };

        fetchImage();

        licenseData();
        introduceData();
    }, []);

    return (
        <ModalPortal>
            <Modal show={isOpen}>
                <div className="modal-body pb-0">
                    <div className="p-4">
                        <div className="main_V_inner">
                            <div
                                className="text-center mb-0 fs-5 border border-bottom-0 border-bottom-rounded-0 font-weight-bold py-3 rounded-top-xl">
                                <span id="business_card_company">{plannerInfo.mem_company}</span>
                            </div>
                            <div className="bg-white p-2 border border-top-0">
                                <div className=" d-flex justify-content-center">
                                    <div className="profile_img">
                                        <img id="business_card_image" src={`${imageSrc}`} className="img-fluid"
                                             alt="프로필사진" />
                                    </div>
                                </div>
                                <div className="text-center pt-3 pb-5">
                                    <h4 id="business_card_name" className="fs-5 mb-0"><a>{plannerInfo.mem_name}</a></h4>
                                    <p id="business_duty" className="mb-0 fs-6">{plannerInfo.mem_duty}</p>
                                </div>

                            </div>

                            <a id="busniess_card_phone"
                               className="d-flex align-items-center py-3 px-3 fs-6 bg-bocare rounded-xl mt-minus-35 text-light point_cursor"
                               style={{ textDecoration: "none" }} onClick={() => openInquiry(plannerInfo.mem_phone)}>
                                <div
                                    className="rounded-circle bg-white d-flex justify-content-center align-items-center me-2 business-icon-size">
                                    <i className="bi bi-messenger text-bocare"></i>
                                </div>
                                1:1 문의하기
                            </a>
                        </div>
                        <div className="d-flex align-items-center py-3 px-3 mt-3 fs-6 bg-light rounded-xl">
                            <div
                                className="rounded-circle bg-bocare d-flex justify-content-center align-items-center me-2 p-2 business-icon-size">
                                <i className="bi bi-mailbox text-white"></i>
                            </div>
                            <span id="emailList">{plannerInfo.mem_email}</span>
                        </div>

                        <div className="d-flex py-3 px-3 mt-3 fs-6 bg-light rounded-xl">
                            <div
                                className="rounded-circle bg-bocare d-flex justify-content-center align-items-center me-2 p-2 business-icon-size">
                                <i className="bi bi-info-circle text-white"></i>
                            </div>
                            <div id="businessInfo" className="info_list ps-0 mb-0 mt-1" style={{ listStyle: "none" }}>
                                {introduce.info_txt}
                            </div>
                        </div>

                        <div className="d-flex py-3 px-3 mt-3 fs-6 bg-light rounded-xl mb-3">
                            <div
                                className="rounded-circle bg-bocare d-flex justify-content-center align-items-center me-2 p-2 business-icon-size">
                                <i className="bi bi-award text-white"></i>
                            </div>
                            <ul className="lisenc_list ps-0 w-100 mb-0 mt-2" style={{ listStyle: "none" }}>
                                {
                                    license && license.length > 0 && license.map((list: any) => (
                                        <li key={list.idx} className="w-100 d-flex justify-content-between mb-2">
                                            <span className="mr-2">
                                                {list.lic_txt}
                                            </span>
                                            {list.file_path != '' && list.file_path != null &&
                                                <>
                                                    <a className="btn btn-outline-bocare py-1 fs-6_5" onClick={() => licenseOpen(list)}><i className="bi bi-card-image me-2 fs-6_5"></i>자격증</a>
                                                    <input type="hidden" value={list.file_path} />
                                                </>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className="pt-3 pb-3 fs-6">
                            <div className="w-100 d-flex">
                                <div className="w-50 pe-1">
                                    <button id="priceBillingLink" type="button" onClick={openInsuranceRequest}
                                            className="btn btn-outline-bocare fs-6 py-3 rounded-pill w-100"><i
                                        className="bi bi-box-arrow-up me-2"></i>보험금청구</button>
                                </div>
                                <div className="w-50 ps-1">
                                    <button id="myTalkLink" type="button" className="btn btn-outline-bocare fs-6 py-3 rounded-pill w-100" onClick={openPlannerBoard}><i
                                        className="bi bi-chat-square-text me-2"></i><span id='plannerName'></span> 게시판</button>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 pb-5 fs-6">
                            <div className="d-flex bd-highlight share_box">
                                <div className="flex-fill pr-icon text-left">
                                    <div className="sns_text d-flex" style={{ listStyle: "none" }}>
                                        <li style={{ display: "none" }} className="display_clear flex-fill">
                                            <a href="http://naver.me/xfcB5QOg" className="">
                                                <img className="w-100 icon-img" src="../v2/views/planner/main/images/icon_v2/webzine.svg" alt="웹진" />
                                            </a>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a className="w-100 btn btn-secondary py-2" onClick={onClose}>
                        닫기
                    </a>
                </div>
            </Modal>
            {licenseModalOpen && (
                <ModalPortal>
                    <LicenseModal onClose={HandleLicenseModalShow} licenseData={licenseInfo} isOpen={true} />
                </ModalPortal>
            )}
        </ModalPortal>
    );
}

export default ProfileModal;