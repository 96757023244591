import React, { Component, useCallback, useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import '../components/css/findfp_main.scss';
import '../components/css/findfp_common.scss';
import '../components/css/business_card.scss';
import axios from "axios";
import { UrlParameters } from "../components/UrlParameters";
import { MoreAndMore } from "../components/more_and_more";
import { HtmlElements } from "../components/HtmlElements";
import ProfileModal from "./ProfileModal";
import ModalPortal from "./ModalPortal";
import FilterModal from "./FilterModal";
import {Simulate} from "react-dom/test-utils";
import {BlobServiceClient}  from "@azure/storage-blob";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
        search?: string;
    }
}

function FPList() {
    const [loading, setLoading] = useState(false);
    const [originList, setOriginList] = useState([]);
    const [list, setList] = useState([]);
    const [plannerData, setPlannerData] = useState({});
    const [searchData, setSearchData] = useState(UrlParameters.getUrlParams().searchData);
    const [modalOpen, setModalOpen] = useState<boolean>();
    const [filterModalOpen, setFilterModalOpen] = useState<boolean>();
    let [professionalChecked, setProfessionalChecked] = useState("전체");
    let [responseAreaChecked, setResponseAreaChecked] = useState("전체");
    const restUrl = process.env.REACT_APP_FINDFP_REST_URL!;
    const imageHost = process.env.REACT_APP_FINDFP_IMAGE_HOST!;

    const blobServiceClient = new BlobServiceClient(`https://${process.env.REACT_APP_STORAGE_ACCOUNT}.blob.core.windows.net/?${process.env.REACT_APP_SAS_TOKEN}`)
    const containerClient = blobServiceClient.getContainerClient("bocare");

    let getPlannerInfo = async(planner_id : number) => {
        const result = await axios.get(restUrl, { params: { type: "planner", plannerId: planner_id } });
        setPlannerData(result.data);
    }

    const HandleModalShow = () => {
        setModalOpen(false);
    };

    const HandleFilterModalShow = () => {
        setFilterModalOpen(false);
    }

    let openProfile = async(planner_id : number) => {
        await getPlannerInfo(planner_id);
        setModalOpen(true);
    }

    let openInquiry = (planner_phone: any) => {
        window.open(`${process.env.REACT_APP_BOCARE_URL}v2/views/planner/insurance_inquiry/inquiry/index.html?phone=${planner_phone}&loc=findfp`, '1:1문의', 'width=600, height=1700');
    }


    const fetchImage = async (clearEmail : any) => {
        try {
            const cleanEmail = clearEmail.replace(/\./g, '').replace(/@/g, '');
            const blobClient = containerClient.getBlobClient(`planner/${cleanEmail}/profile`)
            const response = await blobClient.download();
            const blob = await response.blobBody;
            // @ts-ignore
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl;
        } catch (e) {
            return  "./images/no_profile_photo.png";
        }
    };


    const [imgSrcList, setImgSrcList] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const allPlannerData = async () => {
            setOriginList([]);
            setList([]);
            const result = await axios.get(restUrl, { params: { type: "all", searchData: searchData }});
            setOriginList(result.data);

            const newImgSrcList: { [key: string]: string } = {};
            const fetchImagePromises: Promise<void>[] = [];

            for (let i = 0; i < result.data.length; i++) {
                const idx = result.data[i].idx;
                fetchImagePromises.push(
                    fetchImage(result.data[i].mem_email).then(imgSrc => {
                        if (imgSrc) {
                            newImgSrcList[idx] = imgSrc;
                        }
                    })
                );
            }
            await Promise.all(fetchImagePromises);
            setList(result.data);
            setImgSrcList(newImgSrcList);
        };

        allPlannerData().catch(console.error);
    }, [searchData]);

    const onSearchData = (data : string) => {
        setSearchData(data);
    }

    const onKeyPressEnter = (e : any) => {
        if(e.key === "Enter"){
            setSearchData(e.target.value);
        }
    }

    useEffect(() => {
        let filterList = originList;
        if(professionalChecked != "전체"){
            filterList = filterList.filter((item:any) => {
                if(item.professional == null){
                    item.professional = "";
                }
                return item.professional.indexOf(professionalChecked) > -1;
            })
        }

        if(responseAreaChecked != "전체"){
            filterList = filterList.filter((item:any) =>{
                if (item.response_area == null) {
                    item.response_area = "";
                }
                return item.response_area.indexOf(responseAreaChecked) > -1;
            });
        }

        setList(filterList);
    },[professionalChecked, responseAreaChecked])

    useEffect(() => {
        new MoreAndMore(8, "buttonDiv").initializeMoreAndMore();
    })

    // @ts-ignore
    return (
        <Layout>
            <div className="container container-community">
                <div className="d-flex mt-2 mb-3">
                    <div className="input-group mx-auto">
                        <input name="searchData" id="searchData" type="text"
                               className="form-control rounded-0 ps-3 pe-5 py-3 border-bocare border-3 border-end-0"
                               placeholder="질병/키워드/설계사/보험사명을 입력하세요." aria-label="질병/키워드/설계사/보험사명을 입력하세요." aria-describedby="button-addon2" onKeyPress={(e) => onKeyPressEnter(e)}/>
                        <button className="btn rounded-0 text-bocare border-bocare border-3 border-start-0 bocare-search-button-color"
                                type="submit" id="searchDataButton" name="searchDataButton" onClick={() => onSearchData(HtmlElements.GetByID<HTMLInputElement>("searchData").value)}><i
                            className="bi bi-search fs-5"></i></button>
                    </div>
                </div>
                <hr className="border border-5 mt-0" />
                <div className="d-flex px-3">
                    <div>
                        <div>· 전문 분야 <span id="" className="ms-2 text-pink">{professionalChecked}</span></div>
                        <div>· 담당 지역 <span id="" className="ms-2 text-pink">{responseAreaChecked}</span></div>
                        <div>· 검색 결과 <span id="planner_count" className="ms-2 text-pink">{list.length}명</span></div>
                    </div>
                    <div className="ms-auto">
                        <a className="text-bocare point_cursor" name="filter_modal" onClick={() => setFilterModalOpen(true)}><i className="bi bi-filter-circle-fill"></i> 필터</a>
                    </div>
                </div>
                <hr className="border border-5" />

                <div className="row p-3 pt-0" id="fpList_wrapper">
                    {
                        list && list.length > 0 && list.map((lists:any) => (
                            <div key={lists.idx} className="col-12 col-xl-6 py-4 py-xxl-5 px-0 px-xxl-3 border-bottom moreAndMore">
                                <div className="w-100 d-flex align-items-center">
                                    <div className="me-3">
                                        <div className="webzien-profile-img-box">
                                            <img id="webzineProfile" className="webzine-profile-img-size"
                                                 src={imgSrcList[lists.idx]}
                                                 alt="프로필사진" />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="fs-5_5 mb-2">{lists.mem_name} <span className="fs-7 text-secondary">{lists.mem_company}</span>
                                        </div>
                                        <div className="text-bocare fs-7">{lists.professional} <span className="text-secondary">| {lists.response_area}</span>
                                        </div>
                                        <div className="fs-7 text-secondary text-ellipsis_line1">{lists.title}</div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3">
                                    <div className="me-2 w-100">
                                        <button type="button" onClick={() => openProfile(lists.idx)} className="btn btn-outline-bocare btn-sm w-100 py-2">프로필 보기</button>
                                    </div>
                                    <div className="w-100">
                                        <button type="button" onClick={() => openInquiry(lists.mem_phone)} className="btn btn-outline-pink btn-sm w-100 py-2">1:1
                                            문의</button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {list.length == 0 &&
                    <div id="findFPNoSearchData" className="col-md-12 text-center text-secondary card px-3 py-5 mt-3">
                        <i className="bi bi-emoji-wink fs-1"></i>
                        <div className="fs-2 mb-2">조회된 설계사 정보가 없습니다.</div>
                        <div className="fs-5 mb-0">다시 검색해 주세요.</div>
                    </div>
                }
                {modalOpen && (
                    <ModalPortal>
                        <ProfileModal onClose={HandleModalShow} plannerInfo={plannerData} isOpen={true}/>
                    </ModalPortal>
                )}

                {filterModalOpen && (
                    <ModalPortal>
                        <FilterModal
                            onClose={HandleFilterModalShow}
                            isOpen={true}
                            professionalChecked={professionalChecked}
                            responseAreaChecked={responseAreaChecked}
                            setProfessionalChecked={setProfessionalChecked}
                            setResponseAreaChecked={setResponseAreaChecked}
                        />
                    </ModalPortal>
                )}

                <div id="buttonDiv"></div>
            </div>
        </Layout>
    );
}

export default FPList;