import { Modal } from "react-bootstrap";
import ModalPortal from "../../fplist/ModalPortal";

const ContentsCardModalFirst = ({ onClose, contentsImage, isOpen }: any) => {
    return(
        <ModalPortal>
            <Modal show={isOpen}>
            <div className="modal-header">
                    <div className="d-flex align-items-center modal-title">
                        <div>치매보험</div>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f18073e6b26.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1807418841.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f180743e790.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1807467792.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f180748cca1.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f18074b6eeb.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f18074e2124.jpg" alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    )
}

export default ContentsCardModalFirst;