import { Modal } from "react-bootstrap";
import ModalPortal from "../../fplist/ModalPortal";

const ContentsCardModalSeventh = ({ onClose, contentsImage, isOpen }: any) => {
    return(
        <ModalPortal>
            <Modal show={isOpen}>
            <div className="modal-header">
                    <div className="d-flex align-items-center modal-title">
                        <div>펫보험</div>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8b4ebb1.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8b7ffb7.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8ba87f3.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8bd46a7.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8c0f2f3.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8c386f2.jpg" alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    )
}

export default ContentsCardModalSeventh;