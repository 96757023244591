import { Modal } from "react-bootstrap";
import ModalPortal from "../../fplist/ModalPortal";

const ContentsCardModalSixth = ({ onClose, contentsImage, isOpen }: any) => {
    return(
        <ModalPortal>
            <Modal show={isOpen}>
            <div className="modal-header">
                    <div className="d-flex align-items-center modal-title">
                        <div>암의 이해</div>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f170600720b.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f170602a47f.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f170604ed63.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1706071885.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17060979a5.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17060b9db5.jpg" alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    )
}

export default ContentsCardModalSixth;