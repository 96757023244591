import { Modal } from "react-bootstrap";
import ModalPortal from "../../fplist/ModalPortal";

const ContentsCardModalFourth = ({ onClose, contentsImage, isOpen }: any) => {
    return(
        <ModalPortal>
            <Modal show={isOpen}>
            <div className="modal-header">
                    <div className="d-flex align-items-center modal-title">
                        <div>태아보험</div>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811e4f8da.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811e74bd1.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811e9b472.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811ec0b10.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811ee8d77.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811f18d96.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811f3be8e.jpg" alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    )
}

export default ContentsCardModalFourth;