export class UrlParameters {
    static getUrlParams() : any {
        let args:any = { };    

        const query = window.location.search.substring(1);
        const pairs = query.split("&");
        for(let i = 0; i<pairs.length; i++) {
            let pos = pairs[i].indexOf('=');
            if(pos === -1) {
                continue;
            }
            let name = pairs[i].substring(0,pos);
            let value = pairs[i].substring(pos+1);
            value = decodeURIComponent(value);
            args[name] = value;
        }
        return args;
    }
}