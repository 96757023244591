import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import '../components/css/findfp_main.scss';

const Layout = (props : {children: React.ReactNode}) => {
    return (
        <div className="d-flex flex-column main_height">
            <Header/>
            
            <main>
                {props.children}
            </main>

            <Footer/>
        </div>
    );
}

export default Layout;