import React, { Component } from "react";
import Layout from "../Layout/Layout";
import '../components/css/findfp_common.css';
import '../components/css/business_card.css';
import '../components/css/findfp_main.css';
import { HtmlElements } from "../components/HtmlElements";
import ContentsCard from "../Layout/contents_card/ContentsCard";

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        name?: string;
        search?: string;
    }
}

class FPMain extends Component {
    searchCategory(searchData: string) {
        window.location.href = `/fplist?searchData=${searchData}`;
    }

    onKeyPressEnter(e: any) {
        if (e.key === "Enter") {
            this.searchCategory(e.target.value);
        }
    }

    render() {
        return (
            <Layout>
                <>
                    <div className="container container-community mb-3">
                        <div className="d-flex mt-2">
                            <div className="input-group mx-auto">
                                <input name="searchData" id="searchData" type="text"
                                    className="form-control rounded-pill-0 ps-3 pe-5 py-3 border-bocare border-3 border-end-0"
                                    placeholder="질병 / 키워드 / 설계사 / 보험사명 입력" aria-label="질병 / 키워드 / 설계사 / 보험사명 입력"
                                    aria-describedby="button-addon2" onKeyPress={(e) => this.onKeyPressEnter(e)} />
                                <button
                                    className="btn rounded-pill-0 text-bocare border-bocare border-3 border-start-0 bocare-search-button-color"
                                    type="submit" onClick={() => this.searchCategory(HtmlElements.GetByID<HTMLInputElement>("searchData").value)} id="searchDataButton" name="searchDataButton"><i className="bi bi-search fs-5"></i></button>
                            </div>
                        </div>

                        <div className="mt-4 mt-xxl-5">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="pb-2"><i className="bi bi-bookmark-check me-1"></i><span className="text-bocare">분야별</span> 전문 설계사
                                    찾기</div>
                                <div className="fs-6_5 d-web-block d-none">
                                    <a role="button" onClick={() => this.searchCategory("")}>+ 전체보기</a>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="암" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("암보험")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon1.svg" alt="암" />
                                        <span className="d-block">암보험</span>
                                    </a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="치매/간병" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("치매/간병")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon2.svg" alt="치매/간병" />
                                        <span className="d-block">치매/간병</span>
                                    </a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="태아/어린이" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("태아/어린이")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon3.svg" alt="태아/어린이" />
                                        <span className="d-block">태아/어린이</span>
                                    </a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="치아보험" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("치아보험")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon4.svg" alt="치아보험" />
                                        <span className="d-block">치아보험</span></a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="자동차/운전자"
                                        className="d-block text-dark text-center fs-7 text-secondary py-3" role="button" onClick={() => this.searchCategory("자동차/운전자")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon5.svg" alt="자동차/운전자" />
                                        <span className="d-block">자동차/운전자</span>
                                    </a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="펫보험" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("펫보험")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon6.svg" alt="펫보험" />
                                        <span className="d-block">펫보험</span></a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="종신보험" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("종신보험")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon7.svg" alt="종신보험" />
                                        <span className="d-block">종신보험</span>
                                    </a>
                                </div>
                                <div className="col-4 col-xl-3 main_border">
                                    <a name="category_button" search="유병자건강보험"
                                        className="d-block text-dark text-center fs-7 text-secondary py-3" role="button" onClick={() => this.searchCategory("유병자건강보험")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon8.svg" alt="유병자건강보험" />
                                        <span className="d-block">유병자건강보험</span></a>
                                </div>
                                <div className="col-4 col-xl-3 main_border d-web-none d-block">
                                    <a name="category_button" search="" className="d-block text-dark text-center fs-7 text-secondary py-3"
                                        role="button" onClick={() => this.searchCategory("")}>
                                        <img className="community-main-icon-size mx-auto" src="./images/icon9.svg" alt="유병자건강보험" />
                                        <span className="d-block">전체보기</span></a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 mt-xxl-5">
                            <div className="pb-2"><i className="bi bi-pin-map me-1"></i><span className="text-secondary">지역별</span> 전문
                                설계사 찾기</div>
                            <div className="row">
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="서울" role="button" onClick={() => this.searchCategory("서울")}>서울</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="경기" role="button" onClick={() => this.searchCategory("경기")}>경기</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="인천/부천" role="button" onClick={() => this.searchCategory("인천/부천")}>인천/부천</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="대전/충남/세종" role="button" onClick={() => this.searchCategory("대전/충남/세종")}>대전/충남/세종</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="춘천/강원" role="button" onClick={() => this.searchCategory("춘천/강원")}>춘천/강원</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="대구/경북" role="button" onClick={() => this.searchCategory("대구/경북")}>대구/경북</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="청주/충북" role="button" onClick={() => this.searchCategory("청주/충북")}>청주/충북</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="전주/전북" role="button" onClick={() => this.searchCategory("전주/전북")}>전주/전북</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="광주/전남" role="button" onClick={() => this.searchCategory("광주/전남")}>광주/전남</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="부산/울산/경남" role="button" onClick={() => this.searchCategory("부산/울산/경남")}>부산/울산/경남</a>
                                </div>
                                <div className="col-6 col-xl-3">
                                    <a className="btn btn-outline-secondary rounded-pill mb-2 py-3 w-100 fs-6_5" name="category_button"
                                        search="제주" role="button" onClick={() => this.searchCategory("제주")}>제주</a>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-5" />

                        <div className="mt-4 mt-xxl-5">
                            <div className="pb-2"><i className="bi bi-book me-1"></i>알아두면 쓸모있는 보험지식</div>
                            <ContentsCard />
                        </div>

                        <div className="mt-4 mt-xxl-5 mb-5 px-3 py-4 border rounded text-center text-secondary fs-6_5">
                            <div className="mb-3"><i className="bi bi-exclamation-circle me-1"></i>보험 전문가 등록은 아래 버튼을 클릭해주세요.</div>
                            <a href="https://bocare.co.kr/v2/views/main/service_center/notice/notice.html?id=122" target={"_blank"}> 
                                <button className="btn btn-outline-bocare fs-7">전문가 등록</button>
                            </a>
                        </div>

                    </div>
                </>
            </Layout>
        );
    }
}

export default FPMain;