export class Assert {
    static exceptionMessage = " exception occured!";

    static IsNotNull(object: any) {
        console.assert(object != null);
        if (object == null) {
            const message = "null" + this.exceptionMessage;
            alert(message);
            throw message;
        }
    }

    static IsTrue(condition: boolean) {
        console.assert(condition);
        if (condition === false) {
            this.errorMessage(condition);
        }
    }

    static IsNumber(condition: number) {
        if (typeof condition == "number") {
            return;
        } else {
            this.errorMessage(condition);
        }
    }

    private static errorMessage(errorData: any) {
        const message = typeof errorData + this.exceptionMessage;
        alert(message);
        throw message;
    }
}