import { Modal } from "react-bootstrap";
import ModalPortal from "../../fplist/ModalPortal";

const ContentsCardModalThird = ({ onClose, contentsImage, isOpen }: any) => {
    return(
        <ModalPortal>
             <Modal show={isOpen}>
            <div className="modal-header">
                    <div className="d-flex align-items-center modal-title">
                        <div>운전자보험</div>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4cd3952.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4d049aa.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4d270b9.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4d4d6e3.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4d7f4df.jpg" alt="Image Description" />
                    <img className="img-fluid w-100" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4da4119.jpg" alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    )
}

export default ContentsCardModalThird;