import FINDFP_LOGO from './findfp_logo.svg';

const Header = () => {
    return(
        <header>
            <div className="text-center pt-5 pb-xxl-5 pb-3">
                <a href="/">
                    <img className="community-logo-size" src={FINDFP_LOGO}></img>
                </a>
            </div>
        </header>
    );
}

export default Header;