const Footer = () => {
    return (
        <footer className="bd-footer footer bg-light text-center fs-7 py-5 px-3 mt-auto">
            <img className="mb-2" src="http://www.bocare.co.kr//v2/views/main/image/v3_img/bocare_logo.svg" style={{ width: "100px" }}></img>
            <div className="">(주)에프피하우스 사업자등록번호 : 281-81-00947 | 통신판매신고 : 제 2017-부산남구-0501 호 | 대표 : 문경준 | 부산시 동구 조방로 14 동일타워 413호 | 1566-4875</div>
            <div className="">© 2018 FPHOUSE. All Rights Reserved.</div>
        </footer>
    );
}

export default Footer;