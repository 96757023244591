import ModalPortal from "./ModalPortal";
import Modal from 'react-bootstrap/Modal';
import '../components/css/findfp_main.scss';
import '../components/css/findfp_common.scss';
import '../components/css/business_card.scss';

const LicenseModal = ({ onClose, licenseData, isOpen }: any) => {
    return (
        <ModalPortal>
            <Modal show={isOpen} size="lg">
                <div className="modal-header">
                    <div className="d-flex align-items-center modal-title" id="card_modalLabel">
                        <span>자격증 : </span>
                        <span id="businessModalTitle">{licenseData.lic_txt}</span>
                    </div>
                </div>
                <div className="modal-body">
                    <img className="img-fluid w-100" id="businessModalImage" src={`${process.env.REACT_APP_FINDFP_IMAGE_HOST}bocare/${licenseData.file_path}`} alt="Image Description" />
                </div>
                <div className="d-flex modal-footer w-100">
                    <button type="button" className="w-100 btn btn-secondary" onClick={onClose}>닫기</button>
                </div>
            </Modal>
        </ModalPortal>
    );
}

export default LicenseModal;