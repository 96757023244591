import { HtmlElements } from "./HtmlElements";

export class MoreAndMore {
    rootElement : HTMLElement;
    itemsPerPage: number;

    constructor(itemsPerPage: number, rootElementId: string) {
        this.itemsPerPage = itemsPerPage;
        this.rootElement = HtmlElements.GetByID(rootElementId);
    }

    public initializeMoreAndMore() {
        this.rootElement.innerHTML = "";
        let button = document.createElement("button");
        button.classList.add(...["w-100", "btn", "btn-pink", "py-3"]);
        button.id = "moreButton";
        button.innerHTML = `<i class="bi bi-plus"></i> 더보기`;
        this.rootElement.append(button);

        Array.from(document.querySelectorAll(".moreAndMore")).slice(0, this.itemsPerPage).forEach(element => {
            (<HTMLElement> element).classList.remove("moreAndMore");
        });

        if(document.querySelectorAll(".moreAndMore").length == 0) {
            HtmlElements.GetByID("moreButton").classList.add("d-none");
        }

        HtmlElements.GetByID("moreButton").onclick = () => {
            Array.from(document.querySelectorAll(".moreAndMore")).slice(0, this.itemsPerPage).forEach(element => {
                (<HTMLElement> element).classList.remove("moreAndMore");
            });

            if(document.querySelectorAll(".moreAndMore").length == 0) {
                HtmlElements.GetByID("moreButton").classList.add("d-none");
            }
        }
    }
}