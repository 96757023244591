import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import ModalPortal from '../../fplist/ModalPortal';
import ContentsCardModalFirst from './ContentsCardModalFirst';
import ContentsCardModalFourth from './ContentsCardModalFourth';
import ContentsCardModalSecond from './ContentsCardModalSecond';
import ContentsCardModalThird from './ContentsCardModalThird';
import ContentsCardModalFifth from './ContentsCardModalFifth';
import ContentsCardModalSixth from './ContentsCardModalSixth';
import ContentsCardModalSeventh from './ContentsCardModalSeventh';
import ContentsCardModalEighth from './ContentsCardModalEighth';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function ContentsCard() {
    const [contentsModalFirst, setContentsModalFirst] = useState<boolean>(false);
    const [contentsModalSecond, setContentsModalSecond] = useState<boolean>(false);
    const [contentsModalThird, setContentsModalThird] = useState<boolean>(false);
    const [contentsModalFourth, setContentsModalFourth] = useState<boolean>(false);
    const [contentsModalFifth, setContentsModalFifth] = useState<boolean>(false);
    const [contentsModalSixth, setContentsModalSixth] = useState<boolean>(false);
    const [contentsModalSeventh, setContentsModalSeventh] = useState<boolean>(false);
    const [contentsModalEighth, setContentsModalEighth] = useState<boolean>(false);
    const [imagePath, setImagePath] = useState("");

    const firstClose = () => setContentsModalFirst(false);
    const firstShow = () => setContentsModalFirst(true);

    const secondClose = () => setContentsModalSecond(false);
    const secondShow = () => setContentsModalSecond(true);

    const thirdClose = () => setContentsModalThird(false);
    const thirdShow = () => setContentsModalThird(true);

    const fourthClose = () => setContentsModalFourth(false);
    const fourthShow = () => setContentsModalFourth(true);

    const fifthClose = () => setContentsModalFifth(false);
    const fifthShow = () => setContentsModalFifth(true);

    const sixthClose = () => setContentsModalSixth(false);
    const sixthShow = () => setContentsModalSixth(true);

    const seventhClose = () => setContentsModalSeventh(false);
    const seventhShow = () => setContentsModalSeventh(true);

    const eighthClose = () => setContentsModalEighth(false);
    const eighthShow = () => setContentsModalEighth(true);


    return (
        <CardGroup>
            <Row>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={firstShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f18073e6b26.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>치매보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={secondShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f180d2a6b6d.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>치아보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={thirdShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f17d4cd3952.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>운전자보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={fourthShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f1811e4f8da.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>태아보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={fifthShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_63913615c64ff.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>간편심사</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={sixthShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f170600720b.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>암의 이해</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={seventhShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_63aa9b8b4ebb1.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>펫보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <Card>
                        <a onClick={eighthShow} href="#">
                            <Card.Img variant="top" src="https://bocare.azureedge.net/knowledge/knowledge_64f17c7a6488a.jpg" />
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>상속설계와 종신보험</div>
                                    <div>
                                        <small className="text-muted text-end">Click<i className="bi bi-chevron-right ms-1"></i></small>
                                    </div>
                                </div>
                            </Card.Body>
                        </a>
                    </Card>
                </Col>
            </Row>




            {contentsModalFirst && (
                <ModalPortal>
                    <ContentsCardModalFirst onClose={firstClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalSecond && (
                <ModalPortal>
                    <ContentsCardModalSecond onClose={secondClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalThird && (
                <ModalPortal>
                    <ContentsCardModalThird onClose={thirdClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalFourth && (
                <ModalPortal>
                    <ContentsCardModalFourth onClose={fourthClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalFifth && (
                <ModalPortal>
                    <ContentsCardModalFifth onClose={fifthClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalSixth && (
                <ModalPortal>
                    <ContentsCardModalSixth onClose={sixthClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalSeventh && (
                <ModalPortal>
                    <ContentsCardModalSeventh onClose={seventhClose} isOpen={true} />
                </ModalPortal>
            )}
            {contentsModalEighth && (
                <ModalPortal>
                    <ContentsCardModalEighth onClose={eighthClose} isOpen={true} />
                </ModalPortal>
            )}
        </CardGroup>
    );
}

export default ContentsCard;