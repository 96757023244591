import { Assert } from "./Assert";


function getElementWithNullCheck(object: Element | HTMLCollectionOf<Element> | NodeListOf<Element>) : boolean | Element | HTMLCollectionOf<Element> | NodeListOf<Element> {
    if (object != null) {
        return object;
    } else {
        Assert.IsNotNull(object);
        return false;
    }
}

export class HtmlElements {
    static GetByID<T extends HTMLElement>(id: string) : T {
        const object = document.getElementById(id)!;
        return <T> getElementWithNullCheck(object);
    }

    static GetByClassName<T extends HTMLElement>(name: string) : HTMLCollectionOf<T> {
        const object = document.getElementsByClassName(name);
        return <HTMLCollectionOf<T>> getElementWithNullCheck(object);
    }

    static GetByName<T extends HTMLElement>(name: string) : NodeListOf<T>{
        const object = document.getElementsByName(name);
        return <NodeListOf<T>> getElementWithNullCheck(object);
    }

    static GetByQuery<T extends Element>(element: Document | Element, query: string) : T {
        const object = element.querySelector(query)!;
        return <T> getElementWithNullCheck(object);
    }

    static GetByQuerys<T extends Element>(element: Document | Element, query: string) : NodeListOf<T> {
        const object = element.querySelectorAll(query);
        return <NodeListOf<T>> getElementWithNullCheck(object);
    }
}