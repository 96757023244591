// src/components/ModalPortal.ts
import { ReactNode } from 'react';
import ReactDom from 'react-dom';
import { HtmlElements } from '../components/HtmlElements';
import '../components/css/findfp_main.scss';
import '../components/css/findfp_common.scss';
import '../components/css/business_card.scss';

interface Props {
  children: ReactNode;
}

const ModalPortal = ({ children }: Props) => {
  const modalRootElement = HtmlElements.GetByID("modal-root");

  return ReactDom.createPortal(children, modalRootElement);
};

export default ModalPortal;